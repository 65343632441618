<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="预订单状态">
              <DictSelect
                field="cusAdvanceOrder"
                :value.sync="searchData.orderStatus"
                style="width: 100%"
                placeholder="请选择订单状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="状态">
              <DictSelect
                field="cusRegistrationStatus"
                :value.sync="searchData.registrationStatus"
                style="width: 100%"
                placeholder="请选择申请状态"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户类型">
              <a-select placeholder="请选择" v-model="searchData.customerType" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in cusTypeData" :key="index">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户来源">
              <a-select placeholder="请选择" v-model="searchData.customer_source">
                <a-select-option :value="item.id" v-for="(item, index) in customerSourceList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="订单类型">
              <a-select placeholder="请选择" v-model="searchData.orderType">
                <a-select-option :value="item.id" v-for="(item, index) in orderTypeData" :key="index">{{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="订单类型">
              <DictSelect
                field="cusOrderType"
                :value.sync="searchData.orderType"
                style="width: 100%"
                placeholder="请选择"
              ></DictSelect>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="门店">
              <a-select placeholder="请选择门店" v-model="searchData.storeId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in storeData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="员工">
              <a-select placeholder="请选择员工" v-model="searchData.managerId" allowClear>
                <a-select-option :value="item.id" v-for="(item, index) in managerData" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户信息">
              <a-input v-model="searchData.generalConditions" placeholder="客户姓名/手机号" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="物业地址">
              <a-input v-model="searchData.detailedAddress" placeholder="小区/栋/单元/室" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="操作日期">
              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="所属信息">
              <a-input v-model="searchData.searchName" placeholder="所属门店/员工"></a-input>
            </a-form-model-item>
          </a-col>-->
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="editEnabel && selectedRows.length == 1 && (selectedRows[0].orderStatus == 0 || selectedRows[0].orderStatus == 1 || selectedRows[0].orderStatus == 2 )" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button v-if="selectedRows.length == 1" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isTransfer" type="primary" @click="handOver">移交</a-button>
        <a-button v-if="selectedRows.length == 1 && selectedRows[0].orderStatus == 0 " type="primary" @click="toHandler('shenqing')">申请预定单</a-button>
        <a-button
          v-if="lostOrder && selectedRows.length > 0 && selectedRows[0].orderStatus == 1"
          type="primary"
          @click="loseEfficacyLost"
        >设为丢单</a-button
        >
        <a-button v-if="setDealt && selectedRows.length == 1 && ((selectedRows[0].orderStatus == 1 && selectedRows[0].verifyStatus == 2 ))" type="primary" @click="toHandler('set_dealt')"
          >设置已成交</a-button>
        <a-button v-if="removeEnable"
                  type="danger" @click="toHandler('del')">批量删除</a-button>
<!--        <a-upload-->
<!--          name="file"-->
<!--          :action="uploadUrl"-->
<!--          :headers="headers"-->
<!--          :showUploadList="false"-->
<!--          @change="uploadChange"-->
<!--        >-->
<!--&lt;!&ndash;          v-if="isImport"&ndash;&gt;-->
<!--          <a-button icon="upload" type="default">导入</a-button>-->
<!--        </a-upload>-->
        <downLoad method="get" api="/api/dealer/customer/dealerCustomerInfo/ExcelDealerCustomerList" :params="searchData" name="经销商客户信息.xls">导出客户信息</downLoad>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>

        <span slot="orderType" slot-scope="text, row">
         <a-tag v-if="row.orderType == 1" color="#f50">
            零售
          </a-tag>
          <a-tag v-if="row.orderType == 2" color="#108ee9">
            项目
          </a-tag>
        </span>
        <span slot="propertyType" slot-scope="text, row">
          <a-tag  v-if="row.propertyType == 0" color="green">
            商品房
          </a-tag>
          <a-tag v-if="row.propertyType == 1" color="orange">
           自建房
          </a-tag>
        </span>
        <span slot="orderStatus" slot-scope="item">
           <a-tag v-if="item == 0" color="gray">
              未申请
            </a-tag>
          <a-tag v-if="item == 1" color="orange">
              已申请
            </a-tag>
          <a-tag v-if="item == 2" color="green">
              已成交
            </a-tag>
          <a-tag v-if="item == 3" color="pink">
              失效
            </a-tag>
          <a-tag v-if="item == 4" color="red">
              丢单
            </a-tag>
          <a-tag v-if="item == 5" color="purple">
              无效
            </a-tag>
          <a-tag v-if="item == 6" color="purple">
              过期
            </a-tag>
        </span>
        <span slot="registrationStatus" slot-scope="item">
          <span v-if="item == 0">未申请</span>
          <span v-if="item == 1">首次申请</span>
          <span v-if="item == 2">二次申请</span>
          <span v-if="item == 3">申请成功</span>
        </span>
        <span slot="verifyStatus" slot-scope="text">
          <a-tag v-if="text == 0" color="#8E8E8E">未提交审核</a-tag>
          <a-tag v-if="text == 1" color="#e6a23c">待审核</a-tag>
          <a-tag v-if="text == 2" color="#108ee9">审核通过</a-tag>
          <a-tag v-if="text == 3" color="#ff7875">审核拒绝</a-tag>
          <a-tag v-if="text == 99" color="#e6a23c">审核中</a-tag>
        </span>
        <span slot="customerSource" slot-scope="text, row"
          >【{{ row.customerName }}】{{ row.companyName }}-{{ row.channelType }}</span>
        <span slot="sourceType" slot-scope="text">
          <a-tag v-if="text == 0">PC端</a-tag>
          <a-tag v-if="text == 1">小程序</a-tag>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerCustomerInfo-edit-modal
        ref="DealerCustomerInfoEditModal"
        @reload="getData"
      ></DealerCustomerInfo-edit-modal>
      <DealerCustomerInfoModal ref="DealerCustomerInfoModal"></DealerCustomerInfoModal>
      <SetDealtModal ref="SetDealtModal" @reload="getData"></SetDealtModal>
      <!-- 选择移交员工 -->
      <CheckStaffPage ref="CheckStaffPage" @reload="getData"></CheckStaffPage>

      <!-- 丢单 -->
      <a-modal
        v-model="visibleLost"
        title="设置丢单"
        @ok="handleOkLost"
        @cancel="handleCanceLost"
        :maskClosable="false"
        width="40%"
        :confirm-loading="confirmLoadingLost"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="备注">
            <a-textarea v-model="orderRemarksLost"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerCustomerInfoEditModal from './components/DealerCustomerInfoEditModal.vue'
import DealerCustomerInfoModal from './components/DealerCustomerInfoModal.vue'
import SetDealtModal from '@/views/dealer_customer_order/components/SetDealtModal.vue'
import CheckStaffPage from '@/views/dealer_customer_source/components/CheckStaffPage.vue'

import {
  delDealerCustomerInfo,
  listDealerCustomerInfo,
  selectByIdDealerCustomerInfo,
  doShenQingDealerCustomerOrder,
  batchQuXiaoDealerCustomerInfo
} from './api/DealerCustomerInfoApi'
import { checkPermission } from '@/utils/permissions'
import {baseURL} from "@/utils/request";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerCustomerInfo',
  components: {
    DealerCustomerInfoEditModal,
    SetDealtModal,
    CheckStaffPage,
    DealerCustomerInfoModal
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      advanceOrderList: [],
      customerTypeList: [],
      customerSourceList: [],
      visibleLost: false,
      confirmLoadingLost: false,
      orderRemarksLost: '',
      orderRemarks: '',
      storeData: [],
      managerData: [],
      orderTypeList: [],
      addEnable: checkPermission('dealer:customer:add'),
      editEnabel: checkPermission('dealer:customer:edit'),
      removeEnable: checkPermission('dealer:customer:remove'),
      setDealt: checkPermission('dealer:customer:set_dealt'),
      isTransfer: checkPermission('dealer:customer:transfer'),
      lostOrder: checkPermission('dealer:customer:lost_order'),
      isImport: checkPermission('dealer:customer:import'),
      cusTypeData: [],
      orderTypeData: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/dealer/customer/dealerCustomerInfo/excelReader',
      //上传文件请求头
      headers: {
        'authorization-distributor': this.$store.getters.token,
      },
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {

    //导入
    uploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      listDealerCustomerInfo({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.getSearchData()
    },

    // 设为丢单
    loseEfficacyLost() {
      if (this.selectedRowKeys.length == 0 || this.selectedRowKeys.length > 1) {
        return this.$message.warning('请选择一条数据')
      }
      this.visibleLost = true
    },

    handleCanceLost() {
      this.visibleLost = false
      this.confirmLoadingLost = false
      this.orderRemarksLost = ''
    },

    handleOkLost() {
      this.confirmLoadingLost = true
      let obj = {
        orderRemarks: this.orderRemarksLost,
        id: this.selectedRows[0].id,
      }
      this.axios
        .post('/api/dealer/dealer/customerInfo/doLossOrderForDealer', obj)
        .then((res) => {
          this.$message.success(res.message)
          this.visibleLost = false
          this.orderRemarksLost = ''
          this.selectedRowKeys = []
          this.selectedRows = []
          this.getData()
        })
        .finally(() => {
          this.confirmLoadingLost = false
        })
    },

    getSearchData() {
      this.axios.get(`/api/base/system/dictionary/selectByCode/客户类型`).then((res) => {
        if (res.code == 200) {
          this.cusTypeData = res.body
        }
      })
      this.axios.get(`/api/base/system/dictionary/selectByCode/订单类型`).then((res) => {
        if (res.code == 200) {
          this.orderTypeData = res.body
        }
      })
      this.axios.get(`/api/base/store/dealerStore/storeListForDealer`).then((res) => {
        if (res.code == 200) {
          this.storeData = res.body
        }
      })
      this.axios.get(`/api/base/system/dealer/manager/managerListForDealer`).then((res) => {
        if (res.code == 200) {
          this.managerData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 移交
    handOver() {
      if(this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.$refs.CheckStaffPage.setRowData(this.selectedRowKeys, 'hand_over')
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerCustomerInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerCustomerInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
          case 'check':
          _this.$refs.DealerCustomerInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'shenqing':
          _this.$confirm({
            title: '申请提醒',
            content: '确认要为此客户申请预定单吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await doShenQingDealerCustomerOrder({id:_this.selectedRows[0].id})
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.selectedRowKeys = []
                _this.selectedRows = []
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'del':
          let arr = []
          for (var i = 0; i < this.selectedRows.length; i++) {
            if (arr.indexOf(this.selectedRows[i].orderStatus) === -1) {
              arr.push(this.selectedRows[i].orderStatus)
            }
          }

          if (arr.indexOf(2) >= 0 || arr.indexOf(3) >= 0 ||arr.indexOf(4) >= 0 ) {
            return this.$message.warning('选中的数据中包含不可删除的记录')
          }
          const cusIds = this.selectedRows.map((item) => item.id);

          _this.$confirm({
            title: '删除警告',
            content: '确认要删除选中记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await batchQuXiaoDealerCustomerInfo(cusIds)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'set_dealt':
          _this.$refs.SetDealtModal.show(_this.selectedRows[0],_this.selectedRows[0].id)
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
